// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD4TsgXidOseEbE6M3J5hOMkfa75e2SMiM",
    authDomain: "ham-game-back.firebaseapp.com",
    projectId: "ham-game-back",
    storageBucket: "ham-game-back.firebasestorage.app",
    messagingSenderId: "466650078861",
    appId: "1:466650078861:web:5e9797c514a3a495918cc4",
    measurementId: "G-TWGWMX7F5Q"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const db = firebase.firestore();

export { db };