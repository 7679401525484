import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import './App.css';

const CELL_SIZE = 132;

const MemoryMatchGame = () => {
  // Existing state variables
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [meatsToFind, setMeatsToFind] = useState(1);
  const [meatPositions, setMeatPositions] = useState(new Set());
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const [showMeat, setShowMeat] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [win, setWin] = useState(false);

  const [overlayPosition, setOverlayPosition] = useState({ x: 10, y: 10 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // New state variables for leaderboard and score submission
  const [leaderboard, setLeaderboard] = useState([]);
  const [playerName, setPlayerName] = useState('');
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [scoreSubmitted, setScoreSubmitted] = useState(false); // New state variable

  const totalCells = rows * columns;

  const updateGridSize = () => {
    const newColumns = Math.floor(window.innerWidth / CELL_SIZE);
    const newRows = Math.floor(window.innerHeight / CELL_SIZE);
    setRows(newRows);
    setColumns(newColumns);
  };

  // Initialize the game
  const initializeGame = () => {
    setScore(0);
    setLevel(1);
    setMeatsToFind(1);
    setGameOver(false);
    setWin(false);
    setGameStarted(false);
    setSelectedPositions(new Set());
    setShowMeat(false);
    setRandomMeatPositions(0.1);
    setScoreSubmitted(false); // Reset score submission status
    setPlayerName(''); // Clear player name
  };

  // Function to set random meat positions
  const setRandomMeatPositions = (fraction = 0.1) => {
    if (totalCells <= 0) return;
    const numMeats = Math.floor(totalCells * fraction);
    const newMeatPositions = new Set();
    while (newMeatPositions.size < numMeats) {
      newMeatPositions.add(Math.floor(Math.random() * totalCells));
    }
    setMeatPositions(newMeatPositions);
  };

  // Start the game
  const startGame = () => {
    setGameStarted(true);
    setScore(0);
    setLevel(1);
    setMeatsToFind(1);
    setGameOver(false);
    setWin(false);
    setScoreSubmitted(false); // Reset score submission status
    setPlayerName(''); // Clear player name
    resetMeatPositionsForLevel(1);
  };

  // Reset meat positions for the level
  const resetMeatPositionsForLevel = (meatsCount) => {
    if (totalCells <= 0) return;
    const newMeatPositions = new Set();
    while (newMeatPositions.size < meatsCount) {
      newMeatPositions.add(Math.floor(Math.random() * totalCells));
    }
    setMeatPositions(newMeatPositions);
    setShowMeat(true);

    setTimeout(() => {
      setShowMeat(false);
    }, 2000);
  };

  // Handle cell clicks
  const handleCellClick = (index) => {
    if (gameOver || !gameStarted || showMeat || win) return;

    if (meatPositions.has(index) && !selectedPositions.has(index)) {
      const newSelectedPositions = new Set(selectedPositions);
      newSelectedPositions.add(index);
      setSelectedPositions(newSelectedPositions);
      setScore((prevScore) => prevScore + 10);

      if (newSelectedPositions.size === meatPositions.size) {
        if (meatsToFind >= totalCells) {
          setWin(true);
        } else {
          const nextMeatsToFind = meatsToFind + 1;
          setLevel((prevLevel) => prevLevel + 1);
          setMeatsToFind(nextMeatsToFind);
          setSelectedPositions(new Set());
          resetMeatPositionsForLevel(nextMeatsToFind);
        }
      }
    } else {
      setGameOver(true);
      setGameStarted(false);
      setRandomMeatPositions(0.1);
    }
  };

  // Restart the game
  const resetGame = () => {
    initializeGame();
    startGame();
  };

  // Handle drag events for the overlay
  const handleDragStart = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    setIsDragging(true);
    setDragStart({ x: clientX - overlayPosition.x, y: clientY - overlayPosition.y });
  };

  const handleDragMove = (e) => {
    if (isDragging) {
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      setOverlayPosition({
        x: clientX - dragStart.x,
        y: clientY - dragStart.y,
      });
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  // Fetch leaderboard from Firestore
  const fetchLeaderboard = async () => {
    try {
      const scoresRef = collection(db, 'scores');
      const q = query(scoresRef, orderBy('score', 'desc'), limit(10));
      const querySnapshot = await getDocs(q);
      const leaderboardData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaderboard(leaderboardData);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    }
  };

  // Submit player's score to Firestore
  const submitScore = async () => {
    if (!playerName.trim()) {
      alert('Please enter your name to submit your score.');
      return;
    }

    try {
      await addDoc(collection(db, 'scores'), {
        name: playerName.trim(),
        score: score,
        date: new Date(),
      });
      await fetchLeaderboard();
      setShowLeaderboard(true);
      setScoreSubmitted(true); // Mark score as submitted
    } catch (error) {
      console.error('Error submitting score:', error);
    }
  };

  // Effects
  useEffect(() => {
    updateGridSize();
    window.addEventListener('resize', updateGridSize);
    return () => window.removeEventListener('resize', updateGridSize);
  }, []);

  useEffect(() => {
    if (rows > 0 && columns > 0) {
      initializeGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, columns]);

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  return (
    <div
      className="game-container"
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div
        className="overlay"
        style={{
          left: `${overlayPosition.x}px`,
          top: `${overlayPosition.y}px`,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
      >
        <p>LEVEL: {level}</p>
        <p>HAM TO FIND: {meatsToFind}</p>
        <p>SCORE: {score}</p>

        {gameOver && (
          <div className="game-over">
            <p style={{ color: 'red' }}>GAME OVER!</p>
            {!scoreSubmitted ? (
              <div className="submit-score">
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={playerName}
                  onChange={(e) => setPlayerName(e.target.value)}
                />
                <button onClick={submitScore}>Submit Score</button>
              </div>
            ) : (
              <p>Your score has been submitted!</p>
            )}
          </div>
        )}

        {win && <p style={{ color: 'green' }}>You Win! Click Restart to play again.</p>}

        {showLeaderboard && (
          <>
            <div className="leaderboard">
              <h3>Leaderboard</h3>
              <ol>
                {leaderboard.map((entry) => (
                  <li key={entry.id}>
                    {entry.name}: {entry.score}
                  </li>
                ))}
              </ol>
            </div>
            <button onClick={() => setShowLeaderboard(false)}>Close Leaderboard</button>
          </>
        )}

        <button
          onClick={gameOver || gameStarted ? resetGame : startGame}
          className="start-button"
        >
          {gameOver || gameStarted ? 'Restart' : 'Start'}
        </button>
      </div>

      <div
        className="grid-container"
        style={{
          gridTemplateColumns: `repeat(${columns}, ${CELL_SIZE}px)`,
          gridTemplateRows: `repeat(${rows}, ${CELL_SIZE}px)`,
          cursor: !showMeat && gameStarted && !gameOver && !win ? 'pointer' : 'default',
        }}
      >
        {Array.from({ length: totalCells }).map((_, index) => (
          <div
            className={`grid-item ${selectedPositions.has(index) ? 'selected' : ''}`}
            key={index}
            onClick={() => handleCellClick(index)}
          >
            <img
              src={
                meatPositions.has(index) && (!gameStarted || showMeat || selectedPositions.has(index))
                  ? '/meat.svg'
                  : '/bone.svg'
              }
              alt={meatPositions.has(index) ? 'Meat' : 'Bone'}
              className={`item-image ${
                meatPositions.has(index) && (!gameStarted || showMeat || selectedPositions.has(index))
                  ? 'meat-image'
                  : 'bone-image'
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemoryMatchGame;